import { app } from 'o365-modules';
import { logger } from 'o365-utils';
import { DataObjectOptions, ItemModel} from './types.ts'

/**
 * Append connected query parameters to the initial where clause
 * @param pOptions - data object construction options
 */
export function appendQueryParameters<T extends ItemModel = ItemModel>(pOptions: DataObjectOptions<T>) {
    try {
        const appId = pOptions.appId ?? 'site';
        if (app?.config?.queryParameters && appId == app.id) {
            const appendParameter = (pKey: string, pType: 'sql'|'def', pValue: any) => {
                if (pType == 'sql') {
                    if (pOptions.sqlStatementParameters == null) { pOptions.sqlStatementParameters = {}; }
                    pOptions.sqlStatementParameters[pKey] = pValue ?? null;
                } else if (pType == 'def') {
                    if (pOptions.definitionProcParameters == null) { pOptions.definitionProcParameters = {}; }
                    pOptions.definitionProcParameters[pKey] = pValue ?? null;
                }
            }

            const appendClause = (clause: string) => {
                pOptions.whereClause = pOptions.whereClause ? `(${pOptions.whereClause}) AND (${clause})` : clause;
            };   
            const getOperator = (binding: string) => {
                switch (binding) {
                    case 'equals':
                        return '=';
                    default:
                        return 'LIKE';
                }
            }

            const getValue = (value: string, binding: string) => {
                switch (binding) {
                    case 'contains':
                        return ` '%${value}%'`;
                    case 'beginswith':
                        return ` '${value}%'`;
                    case 'endswith':
                        return ` '%${value}'`;
                    case 'equals':
                    default:
                        return ` '${value}'`;
                }
            }
            Object.entries(app.config.queryParameters).forEach(([key, _value]) => {
                const value = _value as any;
                if (value?.connection?.dataObject != pOptions.id) { return; }
                let parameterKey = value?.connection?.parameter;
                switch (value?.connection?.type) {
                    case 'whereClause':
                        if (value.connection?.field == null || value.connection?.binding == null) { return; }
                        appendClause(`[${value.connection.field}] ${getOperator(value.connection.binding)} ${getValue(app.queryParameters[key], value.connection.binding)}`);
                        break;
                    case 'sqlStatementParameter':
                        if (value?.connection?.parameter == null) { return; }
                        appendParameter(parameterKey ?? key, 'sql', app.queryParameters[key]);
                        break;
                    case 'definitionProcParameter':
                        if (value?.connection?.parameter == null) { return; }
                        appendParameter(parameterKey ?? key, 'def', app.queryParameters[key]);
                        break;
                }
            });
        }
    } catch (ex) {
        logger.error(ex);
    }
}

export const definitionProcParameters = new Map<string, {
    sqlStatementParameters: Record<string, any>,
    definitionProcParameters: Record<string, any>
}>();

/**
 * Register parameters for definition procs that must always be present. Can be used to also set default values
 * 
 * @param pDefinitionProc - Name of definition proc
 * @param pOptions - Object containnig sqlStatementParameters and/or definitionProcParameters that must be always present. Can define default values
 * @example registerDefinitionProcParameters('', { sqlStatementParameters: { Register_ID: null } })
 */
export function registerDefinitionProcParameters(pDefinitionProc: string, pOptions: {
    sqlStatementParameters: Record<string, any>,
    definitionProcParameters: Record<string, any>
}) {
    definitionProcParameters.set(pDefinitionProc, pOptions);
}